<template>
  <page fluid="lg" title="Editar zona">
    <formulario :zona="zona" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiZonas from "@/services/zonas.js";

export default {
  components: { Formulario },
  data() {
    return {
      zona: {},
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiZonas.cargar(id);
      this.zona = resultado;
    },
    async actualizar() {
      let ok = await apiZonas.actualizar(this.zona);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado la zona");
        this.$router.push({ path: "/administracion/zonas" });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
